<script lang="ts">
  import { Authentication, createAuthenticationContext } from "@ui/authentication";
  import type { AuthenticationMode, SignupResult, FinishSignupResult, CodeVerification } from "@ui/authentication/types";

  export let hideSignup: boolean = false;
  export let preventSignup: boolean = false;
  export let inlineOauthConnect: boolean = false;
  export let hideForgotPassword = false;
  export let disableSmoreLogoLink: boolean = false;
  export let backUrl: string | undefined = undefined;
  export let email: string = "";
  export let firstName: string = "";
  export let lastName: string = "";
  export let errcode: string | undefined = undefined;
  export let userId: string | undefined = undefined;
  export let sa: boolean = false;
  export let canEditNames: boolean = false;
  export let resetPasswordToken: string | undefined = undefined;
  export let resettingFacebookUserToken: string | undefined = undefined;
  export let resettingFacebookUserEmail: string | undefined = undefined;
  export let codeVerification: CodeVerification | undefined = undefined;
  export let norc: boolean = false;

  let pageTitle: string = "Log in";

  const context = createAuthenticationContext({
    mode: "login",
    inlineOAuth: inlineOauthConnect,
    hideSignup: hideSignup || sa,
    preventSignup: preventSignup || sa,
    disableSmoreLogoLink: disableSmoreLogoLink || sa,
    backUrl: backUrl,
    hideForgotPassword: hideForgotPassword,
    firstName: firstName,
    lastName: lastName,
    userId,
    canEditNames
  });

  // we need to get the previous state and use it for seleted mailing list id
  window.onpopstate = popStateHandler;
  function popStateHandler(event: PopStateEvent) {
    const { state } = event;
    if (state) {
      const { mode } = state;
      $context.mode = mode;
    } else {
      updateBrowserLink(window.location.href);
    }
  }

  function updateBrowserLink(newLink: string) {
    if ($context.inlineOAuth || $context.mode === ("login-code" as AuthenticationMode)) {
      // In order to change the URL without actually redirecting and reloacing the page,
      // We use the history for that...
      // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
      window.history.pushState({ mode: $context.mode }, "", newLink);
    } else {
      // In order to support our currernt implementation of Google and Facebook connect,
      // we neede to reload the pages for the jQuery to work
      if (window.location.href !== newLink) {
        window.location.href = newLink;
      }
    }
  }

  function updateMode(pathname: string) {
    const pathNames = pathname.split("/");
    const lastPath = pathNames[pathNames.length - 1];

    switch (lastPath as AuthenticationMode) {
      case "accept-tos":
        $context.mode = "accept-tos";
        pageTitle = "Sign up";
        break;
      case "signup":
        $context.mode = "signup";
        pageTitle = "Sign up";
        break;

      // Reset-compromised-password flow
      case "reset-your-password":
        $context.mode = "reset-your-password";
        pageTitle = "Reset your password";
        break;
      // Forgot-password flow
      case "forgot-password":
        $context.mode = "forgot-password";
        pageTitle = "Forgot password";
        break;
      case "reset-password-email-sent":
        $context.mode = "reset-password-email-sent";
        pageTitle = "Forgot password";
        break;
      case "reset-password":
        $context.mode = "reset-password";
        pageTitle = "Reset password";
        break;

      // Convert Facebook to email login flow
      case "convert-facebook":
        $context.mode = "convert-facebook";
        pageTitle = "Convert Facebook";
        break;
      case "create-fb-password-email-sent":
        $context.mode = "create-fb-password-email-sent";
        pageTitle = "Create password";
        break;
      case "create-fb-password":
        $context.mode = "create-fb-password";
        pageTitle = "Create password";
        break;

      // 2FA login flow
      case "login-code":
        $context.mode = "login-code";
        pageTitle = "Code verification";
        break;

      default:
        $context.mode = "login";
        pageTitle = "Log in";
    }
  }

  updateMode(window.location.pathname);
  updateBrowserLink(window.location.href);

  function doModeChanged(e: { detail: AuthenticationMode }) {
    const newMode = e.detail;
    const newLink = `/${newMode}${window.location.search}`;
    const url = new URL(newLink, window.location.origin);
    updateMode(url.pathname);
    updateBrowserLink(newLink);
  }

  function doLogin(e: { detail: string }) {
    window.location.href = e.detail || $context.backUrl || "/app";
  }

  function doRequire2FA(e: { detail: string }) {
    window.location.href = e.detail;
  }

  function doSignup(evt: { detail: SignupResult }) {
    const result = evt.detail;
    const newLink = result.redirectUrl;

    $context.userId = result.userId;
    $context.firstName = result.firstName;
    $context.lastName = result.lastName;
    $context.canEditNames = result.canEditNames;

    window.location.href = result.redirectUrl;

    // The following code is for getting the first and last names after signup, as next phase of the AB tests
    // if (result.existing) {
    //   window.location.href = result.redirectUrl;
    // } else {
    //   updateBrowserLink(newLink);
    //   updateMode(window.location.pathname);
    // }
  }

  function finishSignup(evt: { detail: FinishSignupResult }) {
    window.location.href = evt.detail.redirectUrl;
  }
</script>

<svelte:head>
  <title>{pageTitle} - Smore</title>
</svelte:head>

<div class="flex flex-col items-center justify-start h-screen login-container">
  <div class="flex-grow" />
  <div class="z-50 flex flex-col justify-between flex-shrink-0 w-1/3 max-w-lg p-5 login-side">
    <div class="w-full h-full p-6 py-8 mx-auto mb-4 bg-white border border-gray-200 rounded-xl">
      <Authentication
        {email}
        {resetPasswordToken}
        {resettingFacebookUserEmail}
        {resettingFacebookUserToken}
        {codeVerification}
        {norc}
        errorCode={errcode}
        on:login={doLogin}
        on:require2FA={doRequire2FA}
        on:signup={doSignup}
        on:modeChanged={doModeChanged}
        on:finishSignup={finishSignup} />
    </div>
    <div class="flex items-center justify-around w-full px-4 pt-2 pb-4 mx-auto">
      <a href="https://www.smore.com/privacy-policy/" target="_blank" class="font-normal clickable text-13 text-nxgray-500 hover:underline"
        >Privacy center</a>
      <a href="https://www.smore.com/terms/" target="_blank" class="font-normal clickable text-13 text-nxgray-500 hover:underline"
        >Terms of service</a>
      <a href="mailto:support@smore.com" class="font-normal clickable text-13 text-nxgray-500 hover:underline">Contact us</a>
    </div>
  </div>

  <div class="flex-grow" />
</div>

<style>
  :global(body) {
    background: #fff;
  }

  .login-side {
    max-width: 440px;
    width: 100%;
  }
</style>
